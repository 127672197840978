import React from 'react'
import GoogleMapReact from 'google-map-react'
import CompanyJSON from "../data/company.json"

const defaultProps = {
  center: {
    lat: 48.16,
    lng: 11.57,
  },
  zoom: 12,
}

const AnyReactComponent = ({ text }) => <div className="mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"><p className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"><strong>LinkFive</strong><br/>{CompanyJSON.companyName}<br/>Zittelstr. 4<br/>80796 München</p></div>

const GoogleMap = () => (
  <div style={{ height: '75vh', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyCgN-ji3gC5dGaYGeMAOirOG2J4yohNHdQ' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <AnyReactComponent
        lat={48.161594}
        lng={11.572858}
      />
    </GoogleMapReact>
  </div>
)

export default GoogleMap